@media (max-width: 500px) {
    .sales-overview{
        display: none;
    }
    .divtblleftheader div{
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    .divSalesReportTblData{
        display: none;
    }
    .divSalesReportLeftHdr{
        width: 50% !important;
    }
    .divSalesReportRightTotal{
        width: 49% !important;
        float: right !important;
    }
    .divOtherTotalData{
        display: block !important;
        height: fit-content !important;
    }
    .divgrosssales{
        display: flex;
        width: 100% !important;
    }
    .divgrosssales div:nth-child(1){
        text-align: left;
        padding-left: 10px;
    }
    .divgrosssales div:nth-child(2){
        text-align: right;
        padding-right: 10px;
    }
    .divnetsales{
        display: flex;
        width: 100% !important;
    }
    .divnetsales div:nth-child(1){
        text-align: left;
        padding-left: 10px;
    }
    .divnetsales div:nth-child(2){
        text-align: right;
        padding-right: 10px;
    }
    .divamt{
        display: flex;
        width: 100% !important;
    }
    .divamt div:nth-child(1){
        text-align: left;
        padding-left: 10px;
    }
    .divamt div:nth-child(2){
        text-align: right;
        padding-right: 10px;
    }
    .divorders{
        display: flex;
        width: 100% !important;
    }
    .divorders div:nth-child(1){
        text-align: left;
        padding-left: 10px;
    }
    .divorders div:nth-child(2){
        text-align: right;
        padding-right: 10px;
    }
    #gistartdate div{
        width: 100%;
        padding-left: 8px;
    }
    #gienddate div{
        width: 100%;
        padding-left: 8px;
    }
}